"use client";
import { Suspense, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { Box, Container, Stack, Typography } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";
import HorizontalChips from "../../../component/Chip/Horizontal";
import useSignInDialog from "../../../component/Dialog/dialogs/appWide/Login";
import Skeleton from "../../../component/Skeleton";

const Section = dynamic(() => import("./Section"), { ssr: false });

const TuneIcon = dynamic(() => import("@mui/icons-material/SettingsRounded"));
const MenuTask = dynamic(() =>
  import("../../../component/Menu/menus/Search/Task")
);
const ChipFilter = dynamic(() => import("../../../component/Chip/Filter"));

function Feed() {
  const [following, setFollowing] = useState([]);
  const [task, setTask] = useState("all");
  const { bottomSheet, device, layout, user, stars } = useStore();
  const ref = useRef();
  const sections = [
    { sort: "trending", entity: "models" },
    { sort: "trending", entity: "papers" },
    { sort: "new", entity: "papers" },
    { sort: "new", entity: "models" },
    { sort: "popular", entity: "papers" },
    { sort: "popular", entity: "models" }
  ];

  useEffect(() => {
    if (user.loaded) {
      setFollowing([
        { id: "all", name: "all" },
        ...(user.isAnonymous
          ? [
              { id: "text-generation", name: "text generation" },
              { id: "chat", name: "chat" },
              { id: "text-to-image", name: "image generation" },
              { id: "object-detection", name: "object detection" }
            ]
          : stars.tasks)
      ]);

      return () => setFollowing([]);
    }
  }, [user, user.loaded, device.isNotPhone, stars.tasks]);

  return (
    <Container id="feed" maxWidth="expanded">
      <Typography
        py={10}
        align="center"
        typography="displayMd"
        color="var(--secondary-color)"
      >
        Your AI feed
      </Typography>
      <Stack spacing={5} pb={5}>
        <Box
          top={{ compact: 4, expanded: 0 }}
          position="sticky"
          bgcolor="var(--surface-dim)"
          zIndex={`calc( var(--zIndex-appBar) - ${
            device.isPhone && layout.navOpen ? 1 : 0
          } )`}
          sx={theme => ({
            ...theme.fadeEdge(),
            transition:
              device.isPhone && (layout.navOpen || bottomSheet.open)
                ? undefined
                : "z-index 1s"
          })}
        >
          <HorizontalChips
            chips={[
              <TuneYourFeed key="tune" />,
              ...(user.isAnonymous === false && stars.tasks.length === 0
                ? [
                    <div key="all">
                      <Skeleton
                        width={66}
                        borderRadius="sm"
                        height={{ compact: 24, expanded: 32 }}
                      />
                    </div>,
                    ...stars.starredTasks.map(({ id }) => (
                      <div key={id}>
                        <Skeleton
                          borderRadius="sm"
                          height={{ compact: 24, expanded: 32 }}
                          width={parseInt(Math.random() * (120 - 60) + 60)}
                        />
                      </div>
                    ))
                  ]
                : following.map(_task => (
                    <ChipFilter
                      key={_task.id}
                      label={_task.name}
                      small={device.isPhone}
                      selected={task === _task.name}
                      onClick={() => {
                        setTask(_task.name);
                        ref.current.scrollIntoView();
                      }}
                    />
                  )))
            ]}
          />
        </Box>
        <Box ref={ref} overflow="hidden" px={{ compact: 2, expanded: 0 }}>
          <Suspense>
            {sections.map(({ sort, entity }) => (
              <Section
                key={sort + entity + task}
                task={task}
                sort={sort}
                entity={entity}
              />
            ))}
          </Suspense>
        </Box>
      </Stack>
    </Container>
  );
}

export default observer(Feed);

const TuneYourFeed = observer(function TuneYourFeed() {
  const { device, menu, user } = useStore();
  const signUp = useSignInDialog("Tune your feed");
  const loading = (
    <div key="tune">
      <Skeleton
        width={150}
        borderRadius="sm"
        height={{ compact: 24, expanded: 32 }}
      />
    </div>
  );

  return user.loaded ? (
    <Suspense fallback={loading}>
      <ChipFilter
        StartIcon={TuneIcon}
        small={device.isPhone}
        label="Tune your feed"
        onClick={
          user.isAnonymous
            ? signUp
            : event =>
                menu.configure({
                  anchor: event.target,
                  Component: TaskFollow,
                  sx: {
                    paper: { maxHeight: "70vh !important" },
                    menuList: { pt: 0, maxHeight: "100%" }
                  }
                })
        }
        sx={{
          mr: 0.5,
          bgcolor: "var(--primary-color)",
          "& p, svg": {
            color: "var(--primary-on-color)"
          }
        }}
      />
    </Suspense>
  ) : (
    loading
  );
});

const TaskFollow = observer(function TaskFollow() {
  const { stars, device, bottomSheet, snackbar, tasks } = useStore();
  const tasksFollowed = stars.tasks.map(task => task.name);

  return (
    <Suspense>
      <MenuTask
        key={stars.tasks.length}
        value={tasksFollowed}
        initOpen={device.isNotPhone || bottomSheet.expanded}
        onChange={(_, values) => {
          const set = new Set(tasksFollowed);
          const newState = new Set(values.map(([task]) => task));

          for (const task of set) {
            if (newState.has(task) === false) {
              const entity = tasks.map.get(task);

              stars.quickSave({ save: false, entity });
              snackbar.notify({
                line1: "Stopped following:",
                line2: task,
                actions: [
                  {
                    label: "Undo",
                    onClick() {
                      snackbar.set.open(false);
                      stars.quickSave({ save: true, entity });
                    }
                  }
                ]
              });
            }
          }

          for (const task of newState) {
            if (set.has(task) === false) {
              const entity = tasks.map.get(task);

              stars.quickSave({ save: true, entity });
              snackbar.notify({
                line1: "Following:",
                line2: task,
                actions: [
                  {
                    label: "Undo",
                    onClick() {
                      snackbar.set.open(false);
                      stars.quickSave({ save: false, entity });
                    }
                  }
                ]
              });
            }
          }
        }}
      />
    </Suspense>
  );
});
