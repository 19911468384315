"use client";
import { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
import { Box, Container, InputBase, Stack, Typography } from "@mui/material";
import { Settings as SettingsIcon } from "@mui/icons-material";

import { observer, useStore } from "../../service/mobx";
// import useDialogUpgrade from "../../component/Dialog/dialogs/appWide/Upgrade";
import useSignInDialog from "../../component/Dialog/dialogs/appWide/Login";
import ChipFilter from "../../component/Chip/Filter";
import ChipAssist from "../../component/Chip/Assist";
import ToolTip from "../../component/Tooltip";
import { filterChipScrollSx } from "../../view/search/Filters";

const ButtonFilled = dynamic(() => import("../../component/Button/Filled"));
const SendOnIcon = dynamic(() =>
  import("@mui/icons-material/ArrowUpwardRounded")
);

function AgentInput({ count }) {
  const [disabled, setDisabled] = useState(false);
  const [userInput, setInput] = useState("");
  const [placeholder, setPlaceholder] = useState("How can Bytez help you?");
  const [selected, setSelected] = useState();
  const [actions] = useState([
    [
      "Ask research question",
      "question:",
      `Search the AI knowledge graph, spanning ${count.nodes.toLocaleString()} entities and ${count.edges.toLocaleString()} relationships`
    ],
    [
      "Search models",
      "search models:",
      `Search ${count.models.toLocaleString()} models`
    ],
    [
      "Search papers",
      "search papers:",
      `Search ${count.papers.toLocaleString()} AI papers`
    ]
  ]);
  const { agent, user, utilities } = useStore();
  const [paperCount] = useState(() => utilities.formatNumber(count.papers));
  const signInDialog = useSignInDialog(
    "You're signed out. Sign in to create a new thread"
  );
  // const upgradeDialog = useDialogUpgrade("Upgrade for more threads");
  const router = useRouter();
  const ref = useRef();
  const nothingSelected = selected === undefined;

  const submitToAgent = async () => {
    let userInputTrimmed = userInput.trim();

    if (agent.maxThreads && user.isAnonymous) {
      signInDialog();
    } else if (userInputTrimmed) {
      setDisabled(true);

      if (selected) {
        const [, placeholder] = actions.find(([label]) => label === selected);

        userInputTrimmed = `${placeholder} ${userInputTrimmed}`;
      }

      await agent.thread.create(userInputTrimmed);

      router.push("/agent");
    }
  };

  useEffect(() => {
    if (userInput === "" && nothingSelected) {
      let rounds = 0;
      let index = 0;
      const placeholders = [
        'I can help you search papers (try "1706.03762" or "attention is all you need")',
        'I can search models (try "deepseek" or "openai/whisper-large-v3-turbo")',
        'I can answer research questions (try "What does the research say about using LSTMs in self-driving cars?")'
      ];

      const id = setInterval(() => {
        const placeholder = placeholders[index++];

        if (placeholder === undefined) {
          index = 0;

          if (++rounds === 2) {
            clearInterval(id);
          }
        }

        setPlaceholder(placeholder || "How can I help you?");
      }, 6e3);

      return () => clearInterval(id);
    }
  }, [userInput, nothingSelected]);

  return (
    <Container maxWidth="expanded">
      <Typography
        component="p"
        align="center"
        typography={{ compact: "headlineSm", expanded: "headlineLg" }}
        fontWeight={{ compact: 600, expanded: 700 }}
        position="relative"
        mb={-10}
      >
        Your AI Research Co-pilot
      </Typography>
      <Box
        mt={12}
        width="100%"
        borderRadius="var(--shape-md-round)"
        sx={{
          p: { compact: 1, expanded: 2 },
          border: 1,
          borderColor: "var(--outline-variant)",
          transition: "all 0.5s var(--motion-easing-emphasized)",
          bgcolor: "var(--surface-container-lowest)",
          "&:hover": {
            bgcolor: "var(--surface-container-low)"
          },
          "& button:hover": {
            bgcolor: "var(--surface-container-highest)"
          }
        }}
      >
        <Stack
          position="sticky"
          bottom={0}
          useFlexGap
          spacing={0.5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={{ compact: 1, expanded: 0, large: 0 }}
        >
          <InputBase
            ref={ref}
            fullWidth
            autoFocus
            multiline
            maxRows={10}
            minRows={5}
            value={userInput}
            placeholder={placeholder}
            disabled={disabled}
            sx={{
              position: "relative",
              typography: "titleMd",
              color: "var(--surface-on-color)",
              ".Mui-disabled": {
                fontStyle: "italic",
                WebkitTextFillColor: "var(--secondary-on-container)"
              }
            }}
            inputProps={{
              sx: { color: "var(--surface-on-color)" }
            }}
            onKeyDown={event => {
              if (
                event.ctrlKey &&
                event.shiftKey === false &&
                event.key === "Enter"
              ) {
                submitToAgent();
              }
            }}
            onChange={event => setInput(event.target.value)}
          />
          <Box alignSelf="flex-start">
            <ButtonFilled
              disabled={disabled || userInput === ""}
              sx={{ borderRadius: "var(--shape-md-round)" }}
              IconEnd={SendOnIcon}
              onClick={submitToAgent}
            />
          </Box>
        </Stack>
        <Stack
          height={32}
          direction="row"
          alignItems="center"
          spacing={1}
          mr={-1}
          sx={filterChipScrollSx}
        >
          {actions.map(([label, placeholder, tooltip]) => (
            <ToolTip key={label} title={tooltip}>
              <ChipFilter
                label={label}
                selected={selected === label}
                onClick={() => {
                  ref.current.querySelector("textarea").focus();

                  setPlaceholder(placeholder);
                  setSelected(selected === label ? undefined : label);
                }}
                sx={{
                  height: 28,
                  typography: "labelSm",
                  borderRadius: "var(--shape-sm)",
                  "& p": {
                    typography: "labelMd",
                    fontWeight: 500
                  }
                }}
              />
            </ToolTip>
          ))}
          <ChipAssist
            label="Tune your AI feed"
            StartIcon={SettingsIcon}
            onClick={() => document.getElementById("feed").scrollIntoView()}
          />
        </Stack>
      </Box>
      <Box px={3}>
        <Typography
          p={1}
          px={3}
          component="p"
          typography="labelLg"
          bgcolor="var(--surface-container-highest)"
          border={1}
          borderTop={0}
          borderColor="var(--outline-variant)"
          borderRadius="var(--shape-lg-bottom)"
          sx={{ textWrap: "balance" }}
          textAlign={{ compact: "center", expanded: "left" }}
        >
          Keeps up with AI for you — {paperCount} papers read,{" "}
          {count.recentPapers.toLocaleString()} this week
        </Typography>
      </Box>
    </Container>
  );
}

export default observer(AgentInput);
