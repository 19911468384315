"use client";
import { ButtonBase, Typography } from "@mui/material";

const ChipAssist = ({
  label,
  disabled,
  elevated,
  StartIcon,
  EndIcon,
  sx = {},
  small,
  ...props
}) => (
  <ButtonBase
    disabled={disabled}
    sx={theme => ({
      height: small ? 24 : 32,
      px: 2,
      borderRadius: "var(--shape-sm)",
      border: `1px solid ${
        disabled
          ? theme.alpha("var(--surface-on-color)", 0.12)
          : "var(--outline-color)"
      }`,
      boxShadow: elevated ? "var(--shadow-1)" : undefined,
      bgcolor: elevated
        ? disabled
          ? theme.alpha("var(--surface-on-color)", 0.12)
          : "var(--surface-color)"
        : "var(--surface-container-low)",
      transition: "var(--transition-all)",
      "&:hover": {
        opacity: 0.92,
        boxShadow: elevated ? "var(--shadow-2)" : undefined,
        bgcolor: theme.alpha("var(--surface-on-color)", 0.08)
      },
      "&:focus": {
        opacity: 0.88,
        color: "var(--surface-on-color)"
      },
      ...sx
    })}
    {...props}
  >
    {StartIcon ? (
      <StartIcon
        sx={theme => ({
          m: -1,
          mr: 1,
          height: 18,
          width: 18,
          color: disabled
            ? theme.alpha("var(--surface-on-color)", 0.38)
            : "var(--primary-color)"
        })}
      />
    ) : undefined}
    <Typography
      sx={theme => ({
        width: "max-content",
        ...theme.typography.lineClamp(1),
        color: disabled
          ? theme.alpha("var(--surface-on-color)", 0.38)
          : "var(--surface-on-color)",
        textTransform: "capitalize",
        typography: "labelMd"
      })}
    >
      {label}
    </Typography>
    {EndIcon ? (
      <EndIcon
        sx={theme => ({
          m: -1,
          mr: 1,
          height: 18,
          width: 18,
          color: disabled
            ? theme.alpha("var(--surface-on-color)", 0.38)
            : "var(--primary-color)"
        })}
      />
    ) : undefined}
  </ButtonBase>
);

export default ChipAssist;
