import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/ArrowForward.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/SearchRounded.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/esm/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/esm/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/esm/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/component/Button/Filled.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/component/Button/Text.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Agent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Feed/index.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/view/discover/Widgets/Client.js");
