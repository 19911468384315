"use client";
import { ButtonBase, Typography } from "@mui/material";

import { CheckRounded as SelectedIcon } from "@mui/icons-material";
import { ShallowLink } from "../../Link";

const ChipFilter = ({
  label,
  selected,
  disabled,
  elevated,
  StartIcon = SelectedIcon,
  EndIcon,
  endIcon,
  href,
  small,
  sx = {},
  ...props
}) => (
  <ButtonBase
    disabled={disabled}
    href={href}
    LinkComponent={href ? ShallowLink : undefined}
    sx={theme => ({
      height: small ? 24 : 32,
      pl: small ? 1 : 1.5,
      px: 2,
      textWrap: "nowrap",
      borderRadius: "var(--shape-sm)",
      border: !disabled && selected ? 0 : 1,
      borderColor: disabled
        ? theme.alpha("var(--surface-on-color)", 0.12)
        : selected
        ? "var(--secondary-container)"
        : elevated
        ? "var(--surface-container-low)"
        : "var(--outline-color)",
      boxShadow: elevated ? "var(--elevation-1)" : undefined,
      bgcolor: elevated
        ? "var(--surface-color)"
        : disabled
        ? theme.alpha("var(--surface-on-color)", 0.12)
        : selected
        ? "var(--secondary-container)"
        : "var(--surface-container-low)",
      transition: "all 300ms var(--motion-easing-standard)",
      "&:hover": {
        opacity: 0.92,
        backgroundImage: `linear-gradient(${theme.alpha(
          "var(--surface-on-variant)",
          0.08
        )}, ${theme.alpha("var(--surface-on-variant)", 0.08)})`,
        backgroundBlendMode: "normal",
        boxShadow: selected ? "var(--elevation-2)" : undefined
      },
      "&:focus": {
        opacity: 0.88,
        color: "var(--surface-on-color)"
      },
      ...sx
    })}
    {...props}
  >
    {(StartIcon !== SelectedIcon && !!StartIcon) || selected ? (
      <StartIcon
        sx={theme => ({
          mr: 1,
          ml: -1,
          height: 18,
          width: 18,
          color: disabled
            ? theme.alpha("var(--surface-on-color)", 0.38)
            : "var(--surface-on-variant)"
        })}
      />
    ) : null}
    <Typography
      textTransform="capitalize"
      sx={theme => ({
        typography: {
          compact: `label${small ? "Sm" : "Md"}`,
          expanded: `label${small ? "Sm" : "Lg"}`
        },
        color: disabled
          ? theme.alpha("var(--surface-on-color)", 0.38)
          : selected
          ? "var(--surface-on-variant)"
          : "var(--surface-on-color)"
      })}
    >
      {label}
    </Typography>
    {endIcon ? (
      endIcon
    ) : EndIcon ? (
      <EndIcon
        label={label}
        sx={theme => ({
          ml: 1,
          mr: -1,
          height: 18,
          width: 18,
          color: disabled
            ? theme.alpha("var(--surface-on-color)", 0.38)
            : selected
            ? "var(--secondary-on-container)"
            : "var(--surface-on-variant)"
        })}
      />
    ) : undefined}
  </ButtonBase>
);

export default ChipFilter;
